<template>
  <div>
    <div class="item hover"
         :class="{active:active==item.user_id}"
         v-for="(item) in list"
         :key="item.key"
         @click="to_chat(item)">
      <div class="item-l">
        <div v-show="false">
          <el-avatar :size="50"
                     :src="item.avatar"></el-avatar>
        </div>
        <div v-show="true"
             class="group">
          <el-avatar :size="27"
                     :src="item.avatar"></el-avatar>
          <el-avatar :size="27"
                     :src="item.avatar"></el-avatar>
          <el-avatar :size="27"
                     :src="item.avatar"></el-avatar>
          <el-avatar :size="27"
                     :src="item.avatar"></el-avatar>
        </div>
      </div>
      <div class="item-r">
        <div class="item-r-t">
          <div class="item-r-t-l">{{item.nick_name}}</div>
          <div class="item-r-t-r">{{item.latest_time}}</div>
        </div>
        <div class="item-r-b">
          <div class="item-r-b-l ellipsis">{{item.last_message}}</div>
          <div class="item-r-b-r "
               v-show="item.unread_count">{{item.unread_count}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {
      active: null
    };
  },
  mounted () { },
  methods: {
    to_chat ({ user_id }) {
      this.$router.push('/homepage/chat/' + user_id)
    },
  },
  watch: {
    $route: {
      handler: function ({ params: { id } }) {
        if (id) {
          this.active = id
          // this.$store.dispatch("jimSdk/jimConversation", { that: this })
        } else {
          this.active = null
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang='scss' scoped>
.item {
  height: 80px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  border-left: solid 1px #fff;
  transition: all 0.8s;
  &-l {
    .group {
      width: 50px;
      height: 50px;
      position: relative;
      & /deep/ .el-avatar:nth-child(1) {
        position: absolute;
        top: 0px;
        left: 0px;
      }
      & /deep/ .el-avatar:nth-child(2) {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      & /deep/ .el-avatar:nth-child(3) {
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
      & /deep/ .el-avatar:nth-child(4) {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
  &-r {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    flex: 1;
    &-t {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      &-r {
        font-size: 12px;
        color: #999;
      }
    }
    &-b {
      display: flex;
      justify-content: space-between;
      margin-top: 9px;
      &-l {
        font-size: 14px;
        color: #999;
      }
      &-r {
        width: 16px;
        height: 16px;
        background: #df2a29;
        border-radius: 16px;
        text-align: center;
        line-height: 16px;
        font-size: 10px;
        color: #fff;
      }
    }
  }
}
</style>