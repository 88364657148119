/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-25 15:53:24
 * @Module: 聊天
 */
<template>
  <div class="chatbox">
    <div class="chatbox-content">
      <chat-friend />
      <router-view />
    </div>
  </div>
</template>
<script>
import chatFriend from "./chatFriend"
export default {
  components: {
    chatFriend
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
<style lang='scss' scoped>
.chatbox {
  padding-top: 80px;
  background: #f7f7f7;
  min-height: 100vh;
  &-content {
    width: 1240px;
    margin: 0 auto;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    background-image: url("../../../static/homepage/chat_no.png");
    background-size: 150px 131px;
    background-position: 700px 325px;
    background-repeat: no-repeat;
    display: flex;
  }
  padding-bottom: 50px;
}
</style>