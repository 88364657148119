/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-23 15:23:49
 * @Module:账单弹出框 
 */
 <template>
  <div class="accountModule">
    <el-dialog :visible.sync="dialogVisible"
               width="30%"
               v-loading="loading">
      <div class="accountModule-box">
        <div class="accountModule-item"
             v-for="(item,index) in list"
             :key="index">
          <div class="accountModule-item-t">
            <span>{{item.detail}}</span>
            <span>{{item.billAmount}}</span>
          </div>
          <div class="accountModule-item-b">
            <span>{{item.created_at}}</span>
            <span>金币余额:{{item.rawBalance}}</span>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next"
                     style="display: flex;justify-content: center;"
                     v-show="pagination.page"
                     :current-page="pagination.page"
                     @current-change="currentChange"
                     :page-size="pagination.pageSize"
                     hide-on-single-page
                     :total="pagination.total">
      </el-pagination>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      loading: true,
      dialogVisible: false,
      list: [],
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
    };
  },
  mounted () { },
  methods: {
    okBtn () {
      this.dialogVisible = false
    },
    showModule () {
      this.dialogVisible = true
    },
    currentChange (page) {
      this._get_list(page)
    },
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.coinBillListApi({ page })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }
    },
  },
  watch: {
    dialogVisible (res) {
      if (res) {
        this._get_list()
      }
    }
  }

};
 </script>
 <style lang='scss' scoped>
.accountModule /deep/ .el-dialog__body {
  overflow: auto;
}
.accountModule-box {
  height: 425px;
}
.accountModule-item {
  height: 85px;
  border-bottom: solid 1px #eee;
  &-t,
  &-b {
    display: flex;
    justify-content: space-between;
  }
  &-t {
    padding-top: 17px;
    font-size: 17px;
    color: #262626;
    line-height: 24px;
  }
  &-b {
    margin-top: 5px;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
  }
}
</style>