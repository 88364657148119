/* 
 * @Author: 曹俊杰 
 * @Date: 2021-03-05 15:45:56
 * @Module: 用户多选
 */
 <template>
  <div class="userCheckbox">
    <el-dialog :title="title"
               :visible.sync="dialogVisible"
               width="640px">
      <div class="userCheckbox-center">
        <div class="userCheckbox-center-l">
          <div class="userCheckbox-center-l-search">
            <el-input placeholder="请输入内容"
                      size="mini"
                      prefix-icon="el-icon-search"
                      v-model="input2">
            </el-input>
          </div>
          <div class="userCheckbox-center-l-list">
            <div class="title">好友</div>
            <div class="friendList">
              <userItem v-for="(item,index) in friendList"
                        :item="item"
                        :active="isActive(item)"
                        @click.native="()=>itemClick(item)"
                        :key="index">
                <div class="iconfont iconxuanzhong"
                     style="color:#DF2A29"></div>
              </userItem>
              <!-- <div class="item"
                   :class="{active:isActive(item) }"
                   @click="()=>itemClick(item)"
                   v-for="(item,index) in friendList"
                   :key="index">
                <el-avatar :size="34"
                           :src="item.avatar"></el-avatar>
                <div class="username">{{item.username}}</div>
                <div class="iconfont iconxuanzhong"
                     style="color:#DF2A29"></div>
              </div> -->
            </div>
            <div class="title solid">我的关注</div>
            <div class="friendList">
              <!-- <userCheckboxItem :active="isActive(item,that)" /> -->
              <userItem v-for="(item,index) in followedList"
                        :item="item"
                        :active="isActive(item)"
                        @click.native="()=>itemClick(item)"
                        :key="index">
                <div class="iconfont iconxuanzhong"
                     style="color:#DF2A29"></div>
              </userItem>
            </div>
            <div class="title solid">关注我的</div>
            <div class="friendList">
              <userItem v-for="(item,index) in fansList"
                        :item="item"
                        :active="isActive(item)"
                        @click.native="()=>itemClick(item)"
                        :key="index">
                <div class="iconfont iconxuanzhong"
                     style="color:#DF2A29"></div>
              </userItem>
              <!-- <div class="item"
                   :class="{active:isActive(item) }"
                   @click="()=>itemClick(item)"
                   v-for="(item,index) in fansList"
                   :key="index">
                <el-avatar :size="34"
                           :src="item.avatar"></el-avatar>
                <div class="username">{{item.username}}</div>
                <div class="iconfont iconxuanzhong"
                     style="color:#DF2A29"></div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="userCheckbox-center-r">
          <div class="title">
            <div class="title-l">创建群聊</div>
            <div class="title-r">已选择{{activeListRender.length}}位用户</div>
          </div>
          <div class="activeList">
            <userItem v-for="(item,index) in activeListRender"
                      :item="item"
                      @click.native="()=>itemClick(item)"
                      :key="index">
              <div class="iconfont iconyichu"
                   style="color:#ccc;display:block;"></div>
            </userItem>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
import userItem from "@/views/homepage/chat/module/userCheckbox/userItem"
export default {
  props: {
    title: {
      type: String,
      default: "提示"
    }
  },
  components: {
    userItem
  },
  data () {
    return {
      dialogVisible: true,
      input2: "",
      friendList: [
        {
          username: "安特卫普",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 1
        }
      ],
      followedList: [
        {
          username: "关注者",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 2
        }
      ],
      fansList: [
        {
          username: "粉丝",
          avatar: "https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg",
          uuid: 3
        }
      ],
      activeList: [2]
    };
  },
  computed: {
    activeListRender () {
      let arr = []
      this.activeList.forEach(item => {
        [...this.friendList, ...this.followedList, ...this.fansList].forEach(itm => {
          if (itm.uuid === item) {
            arr.push(itm)
          }
        })
      })
      return arr
    }
  },
  filters: {

  },
  mounted () { },
  methods: {
    setDialogVisible (res) {
      this.dialogVisible = res
    },
    isActive (item) {
      return this.activeList.includes(item.uuid)
    },
    itemClick ({ uuid }) {
      if (this.activeList.includes(uuid)) {
        this.deleteItem(uuid)
      } else {
        this.addItem(uuid)
      }
    },
    // 删除指定item
    deleteItem (uuid) {
      this.activeList.splice(this.activeList.indexOf(uuid), 1)
    },
    addItem (uuid) {
      this.activeList.push(uuid)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.userCheckbox {
  & /deep/ .el-dialog__body {
    padding: 0px;
  }
  &-center {
    width: 640px;
    height: 558px;
    display: flex;
    &-l {
      width: 320px;
      height: 100%;
      border-right: 1px solid #eee;
      &-search {
        width: 280px;
        margin: 0px 20px;
        padding: 12px 0px;
        border-bottom: solid 1px #eee;
      }
      &-list {
        padding-left: 10px;
        padding-right: 10px;
        .title {
          line-height: 26px;
          font-size: 12px;
          color: #666666;
          padding-left: 10px;
        }
        .friendList {
        }
        // .item {
        //   height: 50px;
        //   display: flex;
        //   align-items: center;
        //   width: 300px;
        //   padding-left: 10px;
        //   cursor: pointer;
        //   .username {
        //     color: #262626;
        //     font-size: 14px;
        //     margin-left: 10px;
        //     width: 220px;
        //   }
        //   .iconfont {
        //     display: none;
        //   }
        // }
        // .active {
        //   background: #ffecec;
        //   border-radius: 4px;
        //   .iconfont {
        //     display: block;
        //   }
        // }
      }
    }
    &-r {
      width: 320px;
      height: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        padding: 0 20px;
        &-l {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
        }
        &-r {
          color: #999999;
          line-height: 17px;
          font-size: 12px;
        }
      }
      .activeList {
      }
    }
  }
  .solid {
    border-top: solid 1px #eee;
  }
}
</style>